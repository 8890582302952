import { Helmet } from "react-helmet";
import Imgix from "react-imgix";
import { IMGIX_URL } from "../../util/constants";

import { GenericHeader } from "../header";
import FAQ from "./FAQ";

export const About = () => {
  const aboutButtons = [
    {
      text: "OUR PASTORS",
      path: "/about#pastors",
    },
    {
      text: "OUR MISSION",
      path: "/about#mission",
    },
    {
      text: "BEGINNINGS",
      path: "/about#beginnings",
    },
    {
      text: "I'M NEW!",
      path: "/about#faq",
    },
  ];

  return (
    <div className="about-wrapper">
      <Helmet>
        <title>About our church</title>
        <meta
          name="description"
          content="Learn about our pastors, our mission, and read through some FAQs."
        />
      </Helmet>
      <header className="gen-header-wrapper">
        <GenericHeader title="ABOUT US" buttons={aboutButtons} />
      </header>
      <section className="about-main">
        <h3>Welcome To Coast And Vines Church</h3>
        <p>
          Named after the distinctive location in which it was founded, Coast
          and Vines Church is a part of the Crosslinks Christian Network, an
          international Network of churches and ministries that share a
          commitment to see the kingdom of God come and the will of God being
          done throughout the world.
          <br />
          <br />
          Since beginning in 2006, Coast and Vines has had a call to be a place
          of restoration. Restoring people to God and to one another, restoring
          Godly life patterns, and restoring community to bring transformation
          to regions.
          <br />
          <br />
          We are a vibrant, community-focused church, committed to sharing the
          love, grace, and power of Jesus in our region and beyond.
        </p>
      </section>
      <section className="side-by-side-wrapper" id="pastors">
        <div className="side-by-side pastors">
          <div className="pastors-text">
            <h3>OUR PASTORS</h3>
            <p>
              Joel and Lee-Anne have lived in the Fleurieu community for 15
              years. They have been married for 17 years and have been blessed
              with three lovely children: Nora, Abel and Simeon. The Munro
              family loves the beach, sharing meals with others and planning for
              (or more realistically, dreaming of) adventures around the world.
              <br />
              <br />
              For the last 20 years Joel has worked within Christian education,
              serving in various roles from Primary Teacher to School Principal.
              Lee-Anne has a degree in psychological science, has worked in
              various educational settings and is passionate about strengthening
              an authentic sense of community and inclusion.
              <br />
              <br />
              In 2021, with some surprise, Joel and Lee-Anne felt a call into
              ministry. After much prayer and many grace filled conversations,
              God opened a door for them to return to Adelaide. So, at the start
              of 2022, Joel, Lee-Anne, and family returned from regional South
              Australia, and Joel took on the role of Associate Pastor here at
              Coast & Vines, where they had been in attendance for many years
              prior to moving.
              <br />
              <br />
              Joel and Lee-Anne take great delight in journeying with others and
              seeing the restorative and redemptive work of Jesus take hold in
              the lives of all people. They have a genuine passion for community
              and a desire to see the hope of Jesus spread all around the globe.
            </p>
          </div>
          <Imgix
            src={`${IMGIX_URL}about/joel-and-lee-anne.jpg`}
            sizes="500px"
            srcSetOptions={{ maxWidth: 500 }}
            imgixParams={{ ar: "1:1", fit: "crop" }}
            htmlAttributes={{
              alt: "Pastors Joel and Lee-Anne Munro",
              src: `${IMGIX_URL}about/joel-and-lee-anne.jpg?w=10&auto=format,compress`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
            className="lazyload"
          />
        </div>
      </section>
      <section id="mission" className="side-by-side-wrapper-mission">
        <div className="side-by-side mission">
          <Imgix
            src={`${IMGIX_URL}about/caves.jpeg`}
            sizes="500px"
            srcSetOptions={{ maxWidth: 500 }}
            imgixParams={{ ar: "1:1", fit: "crop" }}
            htmlAttributes={{
              alt: "Port Willunga jetty and caves",
              src: `${IMGIX_URL}about/caves.jpeg?w=10&auto=format,compress`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
            className="lazyload"
          />
          <div className="mission-text">
            <h3>OUR MISSION</h3>
            <p>
              Why do we exist? To follow Jesus, love people, and seek God's
              restoration.
              <br />
              <br />
              Our intention is to be a vibrant, creative, and contemporary
              church where Jesus is encountered, people are empowered, and the
              community is enlivened – locally and globally.
              <br />
              <br />
              We are committed to live with love, honour, hope, joy, grace,
              excellence, and generosity.
              <br />
              <br />
              We will succeed by following Jesus, connecting in the community,
              serving others, and living generously.
              <br />
              <br />
              <i>
                ‘They will rebuild the ancient ruins and restore the places long
                devastated; they will renew the ruined cities that have been
                devastated for generations’
              </i>{" "}
              <br />- Isaiah 61:4
            </p>
          </div>
        </div>
      </section>
      <section className="side-by-side-wrapper" id="beginnings">
        <div className="side-by-side pastors">
          <div className="pastors-text">
            <h3>C&V Beginnings</h3>
            <p>
              Coast and Vines Church emerged from the transformative journey of
              its founding pastors, Rod and Alice Dowie, whose lives were
              forever changed by a profound encounter with Jesus in 1979. Saved
              out of a hippy/drug culture, they embarked on their newfound faith
              with joy and enthusiasm, quickly becoming involved with ministry.
              <br />
              <br />
              Driven by a passion for families and the local church, and having
              been a part of leading two previous churches and helping to
              establish a Christian school in their local district, they both
              felt the call of God to plant a new church in their hometown
              Willunga. In 2006 they started Coast and Vines Church with just a
              few people attending. They spent the next 18 years pouring their
              heart and soul into nurturing the church family and the
              surrounding community.
              <br />
              <br />
              As Rod and Alice embark on a well-deserved retirement, the church
              continues as a testament to their unwavering commitment to
              following Jesus and loving people.
            </p>
          </div>
          <Imgix
            src={`${IMGIX_URL}about/rod-and-alice.jpeg`}
            sizes="500px"
            srcSetOptions={{ maxWidth: 500 }}
            imgixParams={{ ar: "1:1", fit: "crop" }}
            htmlAttributes={{
              alt: "Pastors Rod and Alice Dowie",
              src: `${IMGIX_URL}about/rod-and-alice.jpeg?w=10&auto=format,compress`,
            }}
            attributeConfig={{
              src: "data-src",
              srcSet: "data-srcset",
              sizes: "data-sizes",
            }}
            className="lazyload"
          />
        </div>
      </section>
      <section id="faq">
        <FAQ />
      </section>
    </div>
  );
};
