import { Link } from "react-router-dom";
import Masonry from "react-masonry-css";
import { FiHelpCircle } from "react-icons/fi";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import FAQTile from "./FAQTile";
import content from "../textContent.json";

const FAQ = () => {
	const whereAreYou = (
		<p>
			Our church building is located in the centre of the historic
			township of Willunga in South Australia, about a 50 minute drive
			south of Adelaide City.
			<br />
			<br />
			Head to our{" "}
			<Link to="/contact" className="inline-link">
				contact
			</Link>{" "}
			page for a map!
		</p>
	);
	const whenDoYouMeet = (
		<p>
			Services are held weekly on a Sunday, at 10 AM (on a good day).
			<br />
			<br />
			Details of other gatherings can be found on our{" "}
			<Link to="/connect" className="inline-link">
				connect
			</Link>{" "}
			page.
		</p>
	);
	const whatToExpect = (
		<p>
			A service will generally go for up to 90 minutes. Kicking off at 10
			AM with around 30 minutes of praise and worship.
			<br />
			<br />
			This is then followed by a short welcome and announcing of any
			church notices.
			<br />
			<br />
			Once per month we will spend about 10 minutes taking communion
			together.
			<br />
			<br />
			Following this we hear a bible based message from Pastor Joel, a
			visiting speaker, or from another church member. This message will
			normally go for around 30 minutes.
			<br />
			<br />
			The service is then followed up with more fellowship and more free
			coffee!
		</p>
	);
	const whatAboutKids = (
		<p>
			We are a family-focused church and children are more than welcome!
			<br />
			<br />
			After the time of worship and a short welcome message, children are
			invited to attend one of our two children's programs. "JAM", for
			children who are in kindy to year 2, and "His Kids" for children in
			years 3 to 7.
			<br />
			<br />
			We also have a parent room that can be utilised if need be. This is
			equipped with speakers and windows overlooking the auditorium so you
			don't have to miss out on the service.
			<br />
			<br />
			Visit our{" "}
			<Link to="/programs" className="inline-link">
				programs
			</Link>{" "}
			page for more details.
		</p>
	);
	const denomination = (
		<p>
			Yes, Coast and Vines is a Pentecostal church under the covering of
			the Crosslinks Christian Network, whose vision is to see the Kingdom
			of God come and the will of God being done throughout the world He
			loves.
			<br />
			<br />
			Through strong accountable relationships and a dedicated leadership
			board, they seek to enhance the calling and vision given to each of
			the member churches.
			<br />
			<br />
			Read more about Crosslinks{" "}
			<a
				target="_target"
				rel="noreferrer"
				href="https://www.crosslinknetwork.com.au/"
				className="inline-link"
				aria-label="Link to Crosslinks website"
			>
				here
			</a>
			.
		</p>
	);
	const whereToPark = (
		<p>
			Due to the limited amount of land on the church property, please
			make use of the parking roadside on Aldinga Road.
		</p>
	);
	const whatMusic = (
		<p>
			We have a number of creative and talented worship leaders who lead
			the congregation in a wide variety of worship songs, ranging from
			old hymns to new contemporary praise songs.
		</p>
	);
	const whatToWear = (
		<p>
			We are very much a "come as you are" church, so please wear whatever
			makes you feel comfortable. We generally dress casual.
		</p>
	);
	const watchOnline = (
		<p>
			Yes! We live stream our Sunday services to our{" "}
			<a
				target="_blank"
				rel="noreferrer"
				href={content.youtubeLink}
				className="inline-link"
			>
				YouTube channel
			</a>
			.
		</p>
	);
	const otherQuestions = (
		<p>
			Please get in touch with us using the contact form on our{" "}
			<Link to="/contact" className="inline-link">
				visit us
			</Link>{" "}
			page and we will happily answer your questions.
		</p>
	);

	const { width } = useWindowDimensions();

	const breakpointColumnsObj = {
		default: 3,
		1050: 3,
		920: 2,
		570: 1,
	};
	return (
		<div className="faq-wrapper">
			<div className="faq-tile-wrapper">
				<div className="faq-heading">
					<h3>New to Coast and Vines?</h3>
					<FiHelpCircle className="help-icon" />
					<p>Here are some FAQs to help with your first visit.</p>
				</div>
				{width <= 570 && (
					<Masonry
						breakpointCols={breakpointColumnsObj}
						columnClassName="masonry-grid_column"
						className="masonry-grid"
					>
						<FAQTile title="Where are you?" info={whereAreYou} />
						<FAQTile
							title="When do you meet?"
							info={whenDoYouMeet}
						/>
						<FAQTile
							title="What can I expect at a Sunday service?"
							info={whatToExpect}
						/>
						<FAQTile
							title="What about my kids?"
							info={whatAboutKids}
						/>
						<FAQTile
							title="Are you a part of a denomination?"
							info={denomination}
						/>
						<FAQTile title="Where do I park?" info={whereToPark} />
						<FAQTile
							title="What sort of music do you play?"
							info={whatMusic}
						/>
						<FAQTile title="What do I wear?" info={whatToWear} />
						<FAQTile
							title="Can I watch online?"
							info={watchOnline}
						/>
						<FAQTile
							title="I have other questions!"
							info={otherQuestions}
						/>
					</Masonry>
				)}
				{width > 570 && width <= 920 && (
					<Masonry
						breakpointCols={breakpointColumnsObj}
						columnClassName="masonry-grid_column"
						className="masonry-grid"
					>
						<FAQTile title="Where are you?" info={whereAreYou} />
						<FAQTile
							title="When do you meet?"
							info={whenDoYouMeet}
						/>
						<FAQTile
							title="What can I expect at a Sunday service?"
							info={whatToExpect}
						/>
						<FAQTile
							title="What about my kids?"
							info={whatAboutKids}
						/>
						<FAQTile title="Where do I park?" info={whereToPark} />
						<FAQTile
							title="Are you a part of a denomination?"
							info={denomination}
						/>
						<FAQTile
							title="What sort of music do you play?"
							info={whatMusic}
						/>
						<FAQTile title="What do I wear?" info={whatToWear} />
						<FAQTile
							title="Can I watch online?"
							info={watchOnline}
						/>
						<FAQTile
							title="I have other questions!"
							info={otherQuestions}
						/>
					</Masonry>
				)}
				{width > 920 && (
					<Masonry
						breakpointCols={breakpointColumnsObj}
						columnClassName="masonry-grid_column"
						className="masonry-grid"
					>
						<FAQTile title="Where are you?" info={whereAreYou} />
						<FAQTile
							title="When do you meet?"
							info={whenDoYouMeet}
						/>
						<FAQTile
							title="What can I expect at a Sunday service?"
							info={whatToExpect}
						/>
						<FAQTile
							title="What about my kids?"
							info={whatAboutKids}
						/>
						<FAQTile
							title="Are you a part of a denomination?"
							info={denomination}
						/>
						<FAQTile title="What do I wear?" info={whatToWear} />
						<FAQTile
							title="What sort of music do you play?"
							info={whatMusic}
						/>
						<FAQTile title="Where do I park?" info={whereToPark} />
						<></>
						<></>
						<FAQTile
							title="Can I watch online?"
							info={watchOnline}
						/>
						<FAQTile
							title="I have other questions!"
							info={otherQuestions}
						/>
					</Masonry>
				)}
			</div>
		</div>
	);
};

export default FAQ;
