import { Link } from "react-router-dom";
import classnames from "classnames";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { usePrayerForm } from "./talons/usePrayerForm";
import classes from "./_prayerRequestForm.module.scss";

export const PrayerRequestForm = () => {
	const { register, handleSubmit, onSubmit, errors, toggleForm, formOpen } =
		usePrayerForm();

	return (
		<div className={classes.wrapper}>
			<button
				className={classnames(classes.toggleBtn)}
				onClick={toggleForm}
			>
				Need Prayer?
				{formOpen ? (
					<FiChevronUp className={classes.chevron} size="1.8rem" />
				) : (
					<FiChevronDown className={classes.chevron} size="1.8rem" />
				)}
			</button>
			<div
				className={classnames(classes.collapsible, {
					[classes.open]: formOpen,
				})}
			>
				<p>
					We believe in the power of prayer. Send us an anonymous
					message and we can pray for your situation.
					<br />
					If you would like us to get in touch with you, you can use
					our{" "}
					<Link to="/contact" className="inline-link-white">
						contact form
					</Link>{" "}
					instead.
				</p>
				<form
					id="contact-form"
					onSubmit={handleSubmit(onSubmit)}
					noValidate
				>
					<div>
						<textarea
							className={classes.formInput}
							placeholder="Message *"
							rows={5}
							{...register("message", {
								required: true,
							})}
						></textarea>
						{errors.message && (
							<span className={classes.errorMessage}>
								Please enter a message
							</span>
						)}
					</div>
					<button className={classes.submitBtn} type="submit">
						Submit
					</button>
				</form>
			</div>
		</div>
	);
};
