import { Helmet } from "react-helmet";
import { BiDonateHeart, BiChurch } from "react-icons/bi";
import GivingDetails from "./GivingDetails";
import useWindowDimensions from "../../hooks/useWindowDimensions";
// import { TextButton } from "../buttons";
// import appStore from "../../images/app-store-badge.svg";
// import playStore from "../../images/google-play-badge.svg";

export const Give = () => {
	const { width } = useWindowDimensions();

	const giveTime = (
		<p>
			If you love to cook, clean, garden, or anything else that might
			bless someone, let us know. We can put you in touch with someone in
			need.
		</p>
	);

	// const online = (
	//   <TextButton
	//     text="GIVE ONLINE NOW"
	//     type="outlined-shorter"
	//     onClick={() => window.open("https://tithe.ly/give?c=2108173", "_blank")}
	//   />
	// );
	// const mobile = (
	//   <>
	//     <p>
	//       Head to the "Give" tab in the Church Center app.
	//       <br />
	//       <br />
	//       If you aren't on Church Center yet, head here to find out how to access
	//       and use it.
	//     </p>
	//     <div className="app-btn-cont">
	//       <a
	//         href="https://apps.apple.com/us/app/church-center-app/id1357742931"
	//         target="_blank"
	//         rel="noreferrer"
	//       >
	//         <img src={appStore} alt="app store link" className="store-badge" />
	//       </a>
	//       <a
	//         href="https://play.google.com/store/apps/details?id=com.ministrycentered.churchcenter&hl=en_US&gl=US"
	//         target="_blank"
	//         rel="noreferrer"
	//       >
	//         <img src={playStore} alt="play store link" className="store-badge" />
	//       </a>
	//     </div>
	//   </>
	// );
	// const sms = (
	//   <p>
	//     Text give to{" "}
	//     <a href="sms:+61412123123" className="inline-link-white">
	//       0412 123 123
	//     </a>{" "}
	//     and follow the prompts.
	//   </p>
	// );

	const bank = (
		<p>
			Account Name: <strong>Coast and Vines Church</strong>
			<br />
			Account Number: <strong>157 898 628</strong>
			<br />
			BSB: <strong>633 000</strong>
		</p>
	);

	return (
		<div className="give-wrapper">
			<Helmet>
				<title>Give</title>
				<meta name="description" content="Generosity changes lives." />
				<meta name="robots" content="noindex" />
			</Helmet>
			<header className="give-header-wrapper">
				<div className="give-header-bg"></div>
				<div className="give-header-cont">
					<div className="give-title-container">
						<h1>GENEROSITY CHANGES LIVES</h1>
						<div className="title-line"></div>
					</div>
				</div>
			</header>
			<section className="give-section-wrapper">
				{/* tile layout for single column */}
				{width < 600 && (
					<div className="give-details-wrapper">
						<GivingDetails
							title="GIVE YOUR TIME"
							content={giveTime}
							bgColor="lighter-gray"
							textColor="dark"
						/>
						{/* <GivingDetails
              title="ONLINE"
              content={online}
              bgColor="dark-peach"
            /> */}
						{/* <GivingDetails
              title="MOBILE"
              content={mobile}
              bgColor="middle-green"
            /> */}
						{/* <GivingDetails title="SMS" content={sms} bgColor="light-gray" /> */}
						<GivingDetails
							title="BANK TRANSFER"
							content={bank}
							bgColor="middle-gray"
						/>
					</div>
				)}
				{/* tile layout for two columns */}
				{width >= 600 && width < 900 && (
					<div className="give-details-wrapper">
						<GivingDetails
							title="GIVE YOUR TIME"
							content={giveTime}
							bgColor="dark-gray"
						/>
						<GivingDetails
							bgColor="light-green"
							icon={<BiDonateHeart className="give-icon" />}
						/>
						{/* <GivingDetails
							bgColor="light-green"
							icon={<BiMobileAlt className="give-icon" />}
						/> */}
						{/* <GivingDetails
              title="ONLINE"
              content={online}
              bgColor="dark-peach"
            />
            <GivingDetails
              title="MOBILE"
              content={mobile}
              bgColor="middle-green"
            /> */}
						{/* <GivingDetails
							bgColor="peach"
							icon={<BiCoin className="give-icon" />}
						/> */}
						<GivingDetails
							bgColor="lighter-gray"
							icon={<BiChurch className="give-icon" />}
						/>
						{/* <GivingDetails title="SMS" content={sms} bgColor="light-gray" /> */}
						<GivingDetails
							title="BANK TRANSFER"
							content={bank}
							bgColor="middle-gray"
						/>
					</div>
				)}
				{/* tile layout for three columns */}
				{width >= 900 && (
					<div className="give-details-wrapper">
						<GivingDetails
							title="GIVE YOUR TIME"
							content={giveTime}
							bgColor="light-green"
						/>
						<GivingDetails
							bgColor="lighter-gray"
							icon={<BiDonateHeart className="give-icon" />}
						/>
						{/* <GivingDetails
              title="ONLINE"
              content={online}
              bgColor="dark-peach"
            /> */}
						{/* <GivingDetails
							bgColor="light-green"
							icon={<BiMobileAlt className="give-icon" />}
						/> */}
						{/* <GivingDetails
              title="MOBILE"
              content={mobile}
              bgColor="middle-green"
            /> */}
						{/* <GivingDetails
							bgColor="peach"
							icon={<BiCoin className="give-icon" />}
						/> */}
						{/* <GivingDetails title="SMS" content={sms} bgColor="light-gray" /> */}
						{/* <GivingDetails
							bgColor="lighter-gray"
							icon={<BiChurch className="give-icon" />}
						/> */}
						<GivingDetails
							title="BANK TRANSFER"
							content={bank}
							bgColor="dark-gray"
						/>
					</div>
				)}
			</section>
		</div>
	);
};
