import { TextButtonLink } from "../buttons";

interface genHeaderInterface {
	title: string;
	buttons?: Array<any>;
	className?: string;
}

export const GenericHeader: React.FC<genHeaderInterface> = ({
	title,
	buttons,
	className,
}: genHeaderInterface) => {
	return (
		<div className={`header-cont ${className}`}>
			<div className="title-container">
				<h1>{title}</h1>
				<div className="title-line"></div>
			</div>
			{buttons !== undefined && (
				<div className="button-container">
					{buttons &&
						buttons.map((button, i) => (
							<TextButtonLink
								key={button.text + i}
								text={button.text}
								type="outlined submenu"
								onClick={() =>
									button.onClick.current.scrollIntoView()
								}
								to={button.path}
							/>
						))}
				</div>
			)}
		</div>
	);
};
