import { Helmet } from "react-helmet";

import { GenericHeader } from "../header";
import { ContentCard } from "../contentCard";

export const Programs = () => {
	const jesusAndMe = (
		<p>
			JAM (Jesus And Me) is our program for children who are in kindy to
			year 2.
			<br />
			<br />
			It is a program designed for little ones to learn about who God is,
			the life of Jesus Christ and the gift of the Holy Spirit. Aimed at
			kindy kids all the way to year 2s, content is delivered in a way
			that is accessible for them.
			<br />
			<br />
			We are passionate about seeing kids understand the heart of the
			Father in a fun and safe environment.
		</p>
	);

	const hisKids = (
		<p>
			His Kids is our program for children in school years 3 to 7.
			<br />
			<br />
			It is our desire that each child comes to know Christ and experience
			His love in their lives. Our desire through His Kids, is that
			children will be strengthened by knowing His word from reading the
			bible together. They will also be encouraged by Godly people and
			form friendships with other children.
			<br />
			<br />
			Through our teaching as leaders we hope that each child builds a
			desire to seek God in their lives and follow Him, so that they can
			know Him and shine His light and love to others.
		</p>
	);

	return (
		<div className="programs-wrapper">
			<Helmet>
				<title>Programs</title>
				<meta
					name="description"
					content="Read about our two children's programs for children aged 2 to 7 years."
				/>
			</Helmet>
			<header className="gen-header-wrapper">
				<GenericHeader title="PROGRAMS" />
			</header>
			<section className="about-main">
				<h3>Coast and Vines Church Programs</h3>
				<p>
					For the second half of our Sunday service, we provide two
					children's programs. One for children who are in kindy to
					year 2, and one for children in years 3 to 7.
					<br />
					<br />
					Prior to the sermon being delivered, His Kids will head into
					the foyer while JAM aged children will be invited to leave
					the auditorium through the rear of the room. There is also a
					crèche for little ones 0 - kindy age, carer supervision is
					required. See below for more details.
				</p>
			</section>

			<ContentCard
				title="JAM"
				content={jesusAndMe}
				background="white-bg"
				photoAlignRight={true}
				imgs={[
					"programs/jam-1.jpeg",
					"programs/jam-2.jpeg",
					"programs/jam-3.jpeg",
				]}
			/>
			<ContentCard
				title="His Kids"
				content={hisKids}
				background="gray-bg"
				imgs={[
					"programs/his-kids-1.jpeg",
					"programs/his-kids-2.jpeg",
					"programs/his-kids-3.jpeg",
				]}
			/>
		</div>
	);
};
