import { Helmet } from "react-helmet";
import { GenericHeader } from "../header";
import { TextButtonLink } from "../buttons";

export const NoRoute = () => {
	return (
		<div className="no-route-wrapper">
			<Helmet>
				<title>404</title>
				<meta name="robots" content="noindex" />
			</Helmet>
			<header className="gen-header-wrapper">
				<GenericHeader title="404" />
			</header>
			<section className="about-main">
				<div className="no-route-content">
					<h3>Error: Page not found</h3>
					<div className="return-home-btn-wrapper">
						<TextButtonLink
							text="RETURN HOME"
							type="outlined"
							to={"/"}
						/>
					</div>
				</div>
			</section>
		</div>
	);
};
