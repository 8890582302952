import Imgix from "react-imgix";
import Slider from "react-slick";

import { IMGIX_URL } from "../../util/constants";
import classnames from "classnames";
import classes from "./_contentCard.module.scss";

export const ContentCard = (props) => {
	const {
		alt,
		title,
		imgs,
		content,
		background,
		photoAlignRight,
		useGenericImg = false,
		imgSrc,
	} = props;

	const sliderSettings = {
		autoplay: true,
		autoplaySpeed: 4000,
		arrows: false,
		dots: true,
		fade: true,
		infinite: true,
		speed: 1200,
		slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnHover: false,
	};

	return (
		<section className={classnames(classes.container, `${background}`)}>
			<div
				className={classnames(classes.cardWrapper, {
					[classes.textAlignLeft]: photoAlignRight,
				})}
			>
				<h3>{title && title}</h3>
				<div
					className={classnames(classes.imgWrapper, {
						[classes.photoAlignRight]: photoAlignRight,
					})}
				>
					{imgs && !useGenericImg && (
						<Slider {...sliderSettings}>
							{imgs.map((path, index) => {
								return (
									<Imgix
										key={index + path}
										src={`${IMGIX_URL}${path}`}
										sizes="400px"
										imgixParams={{
											ar: "16:12",
											fit: "crop",
										}}
										htmlAttributes={{
											alt: `${alt} ${index + 1}`,
											src: `${IMGIX_URL}${path}?w=10&auto=format,compress`,
										}}
										attributeConfig={{
											src: "data-src",
											srcSet: "data-srcset",
											sizes: "data-sizes",
										}}
										className="lazyload"
									/>
								);
							})}
						</Slider>
					)}
					{!imgs && useGenericImg && (
						<Slider {...sliderSettings}>
							<img src={imgSrc} alt={alt} />
						</Slider>
					)}
				</div>
				{content}
			</div>
		</section>
	);
};
