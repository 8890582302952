import Imgix from "react-imgix";

import { IMGIX_URL } from "../../util/constants";
import { TextButtonLink } from "../buttons";
import content from "../textContent.json";

const Hero = () => {
	return (
		<div className="hero-container">
			<h3>{content.homePage.heroTitle}</h3>
			<p>{content.homePage.heroContent}</p>
			<TextButtonLink text="LEARN MORE" type="outlined" to={"/about"} />
			<div className="img-container">
				<Imgix
					src={`${IMGIX_URL}home/flourish.jpeg?fp-x=.60&fp-y=.50`}
					sizes="400px"
					srcSetOptions={{ maxWidth: 400 }}
					imgixParams={{
						auto: "compress",
						ar: "1:1",
						fit: "crop",
					}}
					htmlAttributes={{
						alt: "The word Flourish, sitting amongst a flower arrangement",
						rc: `${IMGIX_URL}home/flourish.jpeg?fp-x=.60&fp-y=.50&w=10&auto=format,compress`,
					}}
					attributeConfig={{
						src: "data-src",
						srcSet: "data-srcset",
						sizes: "data-sizes",
					}}
					className="lazyload"
				/>
			</div>
		</div>
	);
};

export default Hero;
