interface FAQTileInterface {
  title: string;
  info: any;
}

const FAQTile: React.FC<FAQTileInterface> = ({
  title,
  info,
}: FAQTileInterface) => {
  return (
    <div className="faq-tile">
      <h4>{title}</h4>
      <div className="tile-info">{info}</div>
    </div>
  );
};

export default FAQTile;
