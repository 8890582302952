import { useState } from "react";
import { useForm } from "react-hook-form";
import { db } from "../../../firebase";
import { toast } from "react-toastify";

export const usePrayerForm = () => {
	const [formOpen, setFormOpen] = useState(false);

	const {
		register,
		getValues,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			message: "",
		},
	});

	const toastifySuccess = (toastId) => {
		toast.update(toastId, {
			render: "Message sent!",
			type: "success",
			isLoading: false,
			position: "bottom-right",
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
		});
	};

	const onSubmit = async () => {
		const toastId = toast.loading("Sending message", {
			position: "bottom-right",
		});

		const { message } = getValues();

		try {
			const prayerRequest = {
				message: message,
			};
			await db
				.collection("prayer-request-form")
				.doc("prayer-request" + Date.now().toString())
				.set(prayerRequest);
			reset();
			toastifySuccess(toastId);
		} catch (e) {
			console.log(e);
		}
	};

	const toggleForm = () => {
		setFormOpen(!formOpen);
	};

	return {
		errors,
		formOpen,
		handleSubmit,
		onSubmit,
		register,
		toggleForm,
	};
};
