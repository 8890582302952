import { useEffect, useState, useRef } from "react";
import { TextButtonLink } from "../buttons";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/logo/logo.svg";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { debounce } from "../../util/helpers";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const NavBar = () => {
	const [openMenu, setOpenMenu] = useState(false);
	const [activeBtn, setActiveBtn] = useState("/");
	const [prevScroll, setPrevScroll] = useState(0);
	const [visible, setVisible] = useState(true);
	const node = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const urlPath = window.location.pathname;

	useOnClickOutside(node, () => setOpenMenu(false));

	useEffect(() => {
		setActiveBtn(urlPath);
	}, [urlPath]);

	const onClick = (path: string) => {
		setActiveBtn(path);
		navigate(path);
		setOpenMenu(false);
	};

	const handleScroll = debounce(() => {
		// find current scroll position
		const currentScroll = window.pageYOffset;
		// set state based on location info
		if (currentScroll <= 100) {
			setVisible(true);
		} else if (currentScroll > prevScroll && visible) {
			setVisible(false);
			setOpenMenu(false);
		} else if (
			currentScroll < prevScroll &&
			prevScroll - currentScroll > 70
		) {
			setVisible(true);
		}
		// set state to new scroll position
		setPrevScroll(currentScroll);
	}, 20);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [prevScroll, visible, handleScroll]);

	const { width } = useWindowDimensions();

	const menuItems = [
		{
			text: "HOME",
			path: "/",
		},
		{
			text: "ABOUT",
			path: "/about",
		},
		{
			text: "PROGRAMS",
			path: "/programs",
		},
		{
			text: "CONNECT",
			path: "/connect",
		},
		{
			text: "CONTACT",
			path: "/contact",
		},
		{
			text: "APP",
			path: "/app",
		},
		{
			text: "GIVE",
			path: "/give",
		},
	];
	return (
		<>
			<div
				className={"menu-spacer " + (visible && "spacer-visible")}
			></div>
			<div className={"nav-wrapper " + (visible && "visible")}>
				<nav className={"" + (visible && "visible")}>
					<div>
						<img
							className="nav-bar-logo"
							src={Logo}
							onClick={() => onClick("/")}
							alt="coast and vines church logo"
							height="55"
							width="128"
						/>
					</div>
					{width >= 960 ? (
						<ul className="horizontal-list">
							{menuItems.map((item, i) => (
								<li key={item.text + i}>
									{
										<TextButtonLink
											type="menu"
											text={item.text}
											onClick={() => onClick(item.path)}
											highlight={activeBtn === item.path}
											to={item.path}
										/>
									}
								</li>
							))}
						</ul>
					) : (
						<div ref={node}>
							<div
								className={
									"hamb-icon " + (openMenu && "btn-open")
								}
							>
								<button
									className="hamb"
									aria-label="Mobile Menu Button"
									onClick={() => setOpenMenu(!openMenu)}
								>
									<span className="hamb-line"></span>
								</button>
							</div>
							<div
								className={
									"side-menu-container " +
									(openMenu && "menu-open")
								}
							>
								<ul className="side-menu-list">
									{menuItems.map((item, i) => (
										<li key={item.text + i}>
											{
												<TextButtonLink
													type="side-menu-btn"
													text={item.text}
													onClick={() =>
														onClick(item.path)
													}
													highlight={
														activeBtn === item.path
													}
													to={item.path}
												/>
											}
										</li>
									))}
								</ul>
							</div>
						</div>
					)}
				</nav>
			</div>
		</>
	);
};
