import classnames from "classnames";
import classes from "./_contentCard.module.scss";

export const ContentCardLoading = (props) => {
	const { background, photoAlignRight = true } = props;

	return (
		<section className={classnames(classes.container, `${background}`)}>
			<div
				className={classnames(classes.cardWrapper, {
					[classes.textAlignLeft]: photoAlignRight,
				})}
			>
				<div className="title-shimmer shimmer"></div>
				<div
					className={classnames(classes.imgWrapper, {
						[classes.photoAlignRight]: photoAlignRight,
					})}
				>
					<div className="image-shimmer shimmer"></div>
				</div>
				<div className="text-content">
					<div className="text-shimmer shimmer"></div>
					<div className="text-shimmer shimmer"></div>
					<div className="text-shimmer shimmer"></div>
					<div className="text-shimmer shimmer"></div>
					<div className="text-shimmer shimmer"></div>
				</div>
			</div>
		</section>
	);
};
