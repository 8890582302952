import { Link } from "react-router-dom";
interface infoTileInterface {
	title: string;
	info: string;
	icon?: any;
	className?: string;
	clickable?: string;
	to?: string;
}

const InfoTile: React.FC<infoTileInterface> = ({
	title,
	info,
	icon,
	className,
	clickable,
	to,
}: infoTileInterface) => {
	return (
		<Link className={`info-tile-wrapper ${clickable}`} to={to ? to : ""}>
			<div className={className}>{icon}</div>
			<div>
				<h4>{title}</h4>
				<p>{info}</p>
			</div>
		</Link>
	);
};

export default InfoTile;
