import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { GenericHeader } from "../header";
import { ContentCard } from "../contentCard";
import { ContentCardLoading } from "../contentCard";
import { useGetFirestoreData } from "../../hooks/useGetFirestoreData";

type GroupDataType = {
	name: string;
	url: string;
	desc: string;
	images: {
		medium: string;
		original: string;
		thumbnail: string;
	};
};

export const Connect = () => {
	const {
		data,
		loading,
	}: { data: { data: GroupDataType[] } | null; loading: boolean } =
		useGetFirestoreData({
			collection: "group-data",
			document: "group-data",
		});

	const groupSections =
		!data?.data || loading ? (
			<ContentCardLoading background="white-bg" />
		) : (
			data.data.map((group, index) => {
				const content = (
					<div
						dangerouslySetInnerHTML={{ __html: group.desc }}
						className="rich-text"
					></div>
				);
				return (
					<div key={group.name}>
						<ContentCard
							title={group.name}
							content={content}
							background={
								index % 2 === 0 ? "white-bg" : "gray-bg"
							}
							photoAlignRight={index % 2 === 0}
							alt={`${group.name} picture`}
							useGenericImg={true}
							imgSrc={group.images.medium}
						/>
					</div>
				);
			})
		);

	return (
		<div className="connect-wrapper">
			<Helmet>
				<title>Connect</title>
				<meta
					name="description"
					content="We love providing a space for people to gather together. Learn about ways you can connect with others."
				/>
			</Helmet>
			<header className="gen-header-wrapper">
				<GenericHeader title="CONNECT" />
			</header>
			<section className="about-main">
				<h3>Connect with others</h3>
				<p>
					At Coast and Vines we are passionate about connection! If
					you would like to meet up with others to chat, have fun,
					create something, or simply just to be around people, check
					out the programs and events below. Please{" "}
					<Link to="/contact" className="inline-link">
						contact us
					</Link>{" "}
					if you'd like to get involved!
				</p>
			</section>
			{groupSections}
		</div>
	);
};
