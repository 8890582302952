interface giveDetailsInterface {
  title?: string;
  content?: any;
  icon?: any;
  bgColor?: string;
  textColor?: string;
}

const GivingDetails: React.FC<giveDetailsInterface> = ({
  title,
  content,
  icon,
  bgColor,
  textColor,
}: giveDetailsInterface) => {
  return (
    <div className={`give-tile-bg ${bgColor}`}>
      {icon && <div className="give-icon-container">{icon}</div>}
      {title && (
        <div
          className={
            textColor ? textColor + " give-tile-text" : "white give-tile-text"
          }
        >
          <h3>{title}</h3>
          {content}
        </div>
      )}
    </div>
  );
};

export default GivingDetails;
