interface textButtonProps {
	text: string;
	type?: string;
	onClick?: any;
	highlight?: boolean;
	icon?: any;
}

export const TextButton: React.FC<textButtonProps> = ({
	text,
	type,
	onClick,
	highlight,
	icon,
}: textButtonProps) => {
	let select = "";
	if (highlight) {
		if (type === "menu") {
			select = "selected";
		} else {
			select = "selected-side";
		}
	}
	return (
		<button
			onClick={onClick}
			className={`${type} ${select} text-button`}
			aria-label={`Link to ${text} page`}
		>
			<div className="text-btn-icon-wrapper">
				{icon && icon}
				{text}
			</div>
		</button>
	);
};
