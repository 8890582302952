import GoogleMapReact from "google-map-react";
import { Helmet } from "react-helmet";
import { BiChurch } from "react-icons/bi";
import { TextButton } from "../buttons";
import ContactForm from "./ContactForm";

const mapStyle = require("./mapStyle.json");

const MapMarker = (props: any) => (
	<div className="map-marker">
		<BiChurch className="church-icon" />
		<p>{props.text}</p>
	</div>
);

export const Contact = () => {
	const mapProps = {
		center: {
			lat: -35.27162886294248,
			lng: 138.5525300569886,
		},
		zoom: 9,
	};

	return (
		<div className="contact-wrapper">
			<Helmet>
				<title>Contact</title>
				<meta
					name="description"
					content="See our location or contact us for more information."
				/>
			</Helmet>
			<header className="contact-header-wrapper">
				<div className="contact-header-cont">
					<div className="title-container">
						<h1>VISIT US</h1>
						<div className="title-line"></div>
					</div>
				</div>
			</header>
			<section className="contact-details-bg">
				<div className="contact-details-wrapper">
					<div className="sunday-service-details">
						<div className="ssd-content">
							<h3>SUNDAY SERVICES</h3>
							<p>
								Join us for worship, teaching, and fellowship.
								<br />
								<br />
								Every Sunday at 10 AM.
								<br />
								<br />
								12-14 Aldinga Road
								<br />
								Willunga SA 5173
								<br />
								<br />
							</p>
							<TextButton
								text="OPEN IN GOOGLE MAPS"
								type="outlined-white"
								onClick={() =>
									window.open(
										"https://goo.gl/maps/SvysK1ucaCj97kfD8",
										"_blank"
									)
								}
							/>
						</div>
					</div>
					<ContactForm />
				</div>
			</section>
			<section className="map-wrapper">
				<GoogleMapReact
					bootstrapURLKeys={{
						key: "AIzaSyDhbCkb2NumHIp1qqfSxzQpHeY_MskWgL8",
					}}
					defaultCenter={mapProps.center}
					defaultZoom={mapProps.zoom}
					options={{ styles: mapStyle }}
				>
					<MapMarker
						lat={mapProps.center.lat}
						lng={mapProps.center.lng}
						text="C&V"
					/>
				</GoogleMapReact>
			</section>
		</div>
	);
};
