import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

type GetFirestoreDataReturnType<T> = {
	data: T | null;
	loading: boolean;
	error: Error | null;
};

export const useGetFirestoreData = <T>({
	collection,
	document,
}: {
	collection: string;
	document: string;
}): GetFirestoreDataReturnType<T> => {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const docRef = doc(db, collection, document);
				const docSnap = await getDoc(docRef);

				if (!docSnap.exists()) {
					throw new Error("Failed to fetch data");
				}

				// Use 'as T' to cast the data to the specified generic type
				setData(docSnap.data() as T);
			} catch (error) {
				setError(error as any);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [collection, document]);

	return { data, loading, error };
};
