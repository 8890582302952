import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Hero from "./Hero";
import KeyInfo from "./KeyInfo";
import content from "../textContent.json";

export const Homepage = () => {
	const urlPath = window.location.pathname;
	useEffect(() => {
		if (urlPath !== "/") {
			window.location.pathname = "/";
		}
	}, [urlPath]);

	return (
		<>
			<Helmet>
				<title>Coast and Vines Church</title>
				<meta
					name="description"
					content="Welcome to Coast and Vines Church! We exist to follow Jesus, love people, and seek God's restoration."
				/>
			</Helmet>
			<header className="home-header">
				<Header />
			</header>
			<section className="section-wrapper">
				<Hero />
			</section>
			<section className="section-wrapper">
				<KeyInfo />
			</section>
			<section className="section-wrapper bible-verse">
				<h2>{content.homePage.bibleVerse}</h2>
				<h3>{content.homePage.bibleVerseLocation}</h3>
			</section>
		</>
	);
};
