import { useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

export function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

// if there is a hash link, scroll to that element
// else, scroll to top
// unless the user has clicked back
export function ScrollOnLoad() {
	const { pathname, hash, key } = useLocation();
	const navType = useNavigationType();

	useEffect(() => {
		if (navType === "POP") {
			return;
		}
		if (hash === "") {
			window.scrollTo({ top: 0, behavior: "smooth" });
		} else {
			setTimeout(() => {
				const id = hash.replace("#", "");
				const element = document.getElementById(id);
				if (element) {
					element.scrollIntoView();
				}
			}, 0);
		}
	}, [pathname, hash, key, navType]);

	return null;
}
