import { Link } from "react-router-dom";

interface textButtonLinkProps {
	text: string;
	type?: string;
	onClick?: any;
	highlight?: boolean;
	icon?: any;
	to?: string;
}

export const TextButtonLink: React.FC<textButtonLinkProps> = ({
	text,
	type,
	onClick,
	highlight,
	icon,
	to,
}: textButtonLinkProps) => {
	let select = "";
	if (highlight) {
		if (type === "menu") {
			select = "selected";
		} else {
			select = "selected-side";
		}
	}
	return (
		<Link
			onClick={onClick}
			className={`${type} ${select} text-button`}
			aria-label={`Link to ${text} page`}
			to={to ? to : ""}
		>
			<div className="text-btn-icon-wrapper">
				{icon && icon}
				{text}
			</div>
		</Link>
	);
};
