import { Background } from "react-imgix";
import Slider from "react-slick";

import content from "../textContent.json";
import { IMGIX_URL } from "../../util/constants";

const slideImages = [
	"home/banner-1.jpeg",
	"home/banner-2.jpeg",
	"home/banner-3.jpeg",
	"home/banner-4.jpeg",
	"home/banner-5.jpeg",
	"home/banner-6.jpeg",
	"home/banner-7.jpeg",
	"home/banner-8.jpeg",
	"home/banner-9.jpeg",
];

const Header = () => {
	const sliderSettings = {
		autoplay: true,
		arrows: false,
		dots: false,
		fade: true,
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnHover: false,
	};

	return (
		<div className="header-content">
			{slideImages && (
				<Slider {...sliderSettings}>
					{slideImages.map((path, index) => {
						return (
							<div className="each-slide" key={index + path}>
								<Background
									src={`${IMGIX_URL}${path}`}
									imgixParams={{ auto: "compress" }}
									htmlAttributes={{
										alt: `Header slideshow image ${
											index + 1
										}`,
										src: `${IMGIX_URL}${path}?w=10&auto=format,compress`,
									}}
									attributeConfig={{
										src: "data-src",
										srcSet: "data-srcset",
										sizes: "data-sizes",
									}}
									className="lazyload"
								/>
							</div>
						);
					})}
				</Slider>
			)}
			<div className="home-header-title">
				<span>{content.homePage.headerTitle}</span>
			</div>
		</div>
	);
};

export default Header;
