import { Helmet } from "react-helmet";
import { GenericHeader } from "../header";
import YoutubeEmbed from "../youtubeEmbed/YoutubeEmbed";

import appStore from "../../images/app-store-badge.svg";
import playStore from "../../images/google-play-badge.svg";

export const ChurchCenter = () => {
	return (
		<div className="church-center-wrapper">
			<Helmet>
				<title>Church Center App</title>
				<meta
					name="description"
					content="Download the Church Center app to keep up to date with Coast and Vines Church."
				/>
			</Helmet>
			<header className="gen-header-wrapper">
				<GenericHeader title="Our App" />
			</header>
			<section className="about-main">
				<h3>The Easiest Way To Stay Up To Date</h3>
				<p>
					Download the Church Center App to stay up to date with
					everything happening at Coast and Vines. You can view our
					event calendar, join small groups, chat to friends, find
					handy links, receive notifications and announcements, and
					more! See the instructions below to get started.
				</p>
			</section>
			<section className="app-download-container">
				<div className="app-download-wrapper text-align-left">
					<h3>Download the app</h3>
					<div className="video-wrapper align-video-right">
						<YoutubeEmbed embedId="iJZfRQytYKA" />
					</div>
					<div>
						<p>
							Click one of the links below to download the app for
							iOS or Android devices.
							<br />
							<br />
							If you need some extra detail on how to get started,
							watch this handy video!
						</p>
						<div className="app-btn-cont">
							<a
								href="https://apps.apple.com/us/app/church-center-app/id1357742931"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={appStore}
									alt="app store link"
									className="store-badge"
								/>
							</a>
							<a
								href="https://play.google.com/store/apps/details?id=com.ministrycentered.churchcenter&hl=en_US&gl=US"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src={playStore}
									alt="play store link"
									className="store-badge"
								/>
							</a>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};
