import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/app-check";

const firebaseConfig = {
	apiKey: "AIzaSyDhbCkb2NumHIp1qqfSxzQpHeY_MskWgL8",
	authDomain: "coast-and-vines-church-website.firebaseapp.com",
	projectId: "coast-and-vines-church-website",
	storageBucket: "coast-and-vines-church-website.appspot.com",
	messagingSenderId: "1038216603915",
	appId: "1:1038216603915:web:01716dda00bc7ed439da0e",
	measurementId: "G-9RV21C0XYV",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
/* eslint-disable-next-line no-restricted-globals */
self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_APPCHECK_DEBUG_KEY;
firebase.appCheck().activate("6Ldq_6QeAAAAAAdGDJ3Z1nlqVufyc1ENPvNQDcfA");

export { db };
