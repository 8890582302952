import InfoTile from "./InfoTile";
import { FiClock, FiHelpCircle, FiNavigation } from "react-icons/fi";

const KeyInfo = () => {
	return (
		<div className="key-info-container">
			<div className="key-info">
				<InfoTile
					icon={<FiClock size="2.4rem" />}
					title="WORSHIP SERVICE"
					info="Join us this Sunday at 10 AM"
					className="icon"
				/>
				<InfoTile
					icon={<FiNavigation size="2.4rem" />}
					title="WILLUNGA, S.A."
					info="12-14 Aldinga Road"
					className="icon"
				/>
				<InfoTile
					icon={<FiHelpCircle size="2.4rem" />}
					title="I'M NEW HERE!"
					info="Click to view our FAQs"
					className="colored-icon"
					clickable="clickable"
					to={"/about#faq"}
				/>
			</div>
		</div>
	);
};

export default KeyInfo;
