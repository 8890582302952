import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { NavBar } from "./components/navBar";
import { Homepage } from "./components/homepage";
import { About } from "./components/about";
import { ChurchCenter } from "./components/churchCenter";
import { Programs } from "./components/programs";
import { Connect } from "./components/connect";
import { Contact } from "./components/contact";
import { Give } from "./components/give";
import { Footer } from "./components/footer";
import { NoRoute } from "./components/noRoute";
import { ScrollOnLoad } from "./util/helpers";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes/plugins/attrchange/ls.attrchange";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {
	return (
		<Router>
			<ScrollOnLoad />
			<div className="main-wrapper">
				<NavBar />
				<Routes>
					<Route path="/" element={<Homepage />}></Route>
					<Route path="/about" element={<About />}></Route>
					<Route path="/programs" element={<Programs />}></Route>
					<Route path="/connect" element={<Connect />}></Route>
					<Route path="/contact" element={<Contact />}></Route>
					<Route path="/give" element={<Give />}></Route>
					<Route path="/app" element={<ChurchCenter />}></Route>
					<Route path="/*" element={<NoRoute />}></Route>
				</Routes>
				<ToastContainer />
				<footer className="footer-wrapper">
					<Footer />
				</footer>
			</div>
		</Router>
	);
};

export default App;
