import { useForm } from "react-hook-form";

import { db } from "../../firebase";

import { toast } from "react-toastify";

type FormData = {
	name: string;
	email: string;
	message: string;
};

const ContactForm = () => {
	const {
		register,
		getValues,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			name: "",
			email: "",
			message: "",
		},
	});

	const toastifySuccess = (toastId: any) => {
		toast.update(toastId, {
			render: "Message sent!",
			type: "success",
			isLoading: false,
			position: "bottom-right",
			autoClose: 4000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
		});
	};

	const onSubmit = async () => {
		const toastId = toast.loading("Sending message", {
			position: "bottom-right",
		});
		const { name, email, message } = getValues();
		const htmlContent = `
      <div style="background-color: #f0f0f0; margin: 0; padding: 15px 15px 20px 15px;">
        <h4 style="color: #333333;">Contact form submission from: ${name}</h4>
        <h4 style="color: #333333;">Email: ${email}</h4>
        <h4 style="color: #333333;">Message:</h4>
        <p style="color: #333333;">${message}</p>
      </div>
    `;
		try {
			const emailData = {
				to: "contact@coastandvineschurch.org.au",
				from: "donotreply@coastandvineschurch.org.au",
				replyTo: email,
				message: {
					subject: "Message from " + name,
					html: htmlContent,
				},
			};
			await db
				.collection("contact-form-emails")
				.doc(email + Date.now().toString())
				.set(emailData);
			reset();
			toastifySuccess(toastId);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<div id="contact-us" className="contact-form-wrapper">
			<h3>CONTACT US</h3>
			<form
				id="contact-form"
				onSubmit={handleSubmit(onSubmit)}
				noValidate
			>
				<div>
					<input
						type="text"
						className="form-control form-input"
						placeholder="Name *"
						{...register("name", {
							required: {
								value: true,
								message: "Please enter your name",
							},
							maxLength: {
								value: 30,
								message: "Please use 30 characters or less",
							},
						})}
					></input>
					{errors.name && (
						<span className="errorMessage">
							{errors.name.message}
						</span>
					)}
				</div>
				<div>
					<input
						type="text"
						className="form-control form-input"
						placeholder="Email *"
						{...register("email", {
							required: true,
							pattern:
								/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
						})}
					></input>
					{errors.email && (
						<span className="errorMessage">
							Please enter a valid email address
						</span>
					)}
				</div>
				<div>
					<textarea
						className="form-control form-input"
						placeholder="Message *"
						rows={5}
						{...register("message", {
							required: true,
						})}
					></textarea>
					{errors.message && (
						<span className="errorMessage">
							Please enter a message
						</span>
					)}
				</div>
				<button className="submit-btn" type="submit">
					Submit
				</button>
			</form>
		</div>
	);
};

export default ContactForm;
