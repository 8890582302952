import { FiFacebook } from "react-icons/fi";
import { FiYoutube } from "react-icons/fi";
import { PrayerRequestForm } from "../contact";
import content from "../textContent.json";
import Logo from "../../images/logo/logo.svg";

export const Footer = () => {
	return (
		<>
			<div className="prayer-request-footer-wrapper">
				<PrayerRequestForm />
			</div>
			<div className="footer-content-wrapper">
				<div className="footer-content">
					{/* <h4>C & V</h4> */}

					<img
						className="footer-logo"
						src={Logo}
						alt="coast and vines church logo"
						width="112"
						height="44"
					/>
				</div>
				<div className="footer-address">
					<p>
						12/14 Aldinga Road
						<br />
						Willunga
						<br />
						South Australia
					</p>
					<div className="footer-socials">
						<a
							href={content.facebookLink}
							target="_blank"
							rel="noreferrer"
							aria-label="Link to Coast and Vines Facebook page"
						>
							<FiFacebook className="footer-icon" size="1.8rem" />
						</a>
						<a
							href={content.youtubeLink}
							target="_blank"
							rel="noreferrer"
							aria-label="Link to Coast and Vines YouTube page"
						>
							<FiYoutube className="footer-icon" size="1.8rem" />
						</a>
					</div>
				</div>
				<p className="copyright">© 2022 Coast and Vines Church</p>
			</div>
		</>
	);
};
